import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimpleMap from "./SimpleMap";
export default function Contact() {
  const contactVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <motion.div
        variants={contactVariants}
        initial="hidden"
        animate="visible"
        style={{ backgroundColor: "rgba(227, 242, 255, 0.88)" }}
      >
        <div className="pt-3">
          <Container>
            <Row>
              <Col xs={12} sm={12} lg={6}>
                <div>
                  <h5>DROP US A LINE</h5>
                  <hr />
                  <Form
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      padding: "20px",
                    }}
                  >
                    <Form.Group controlId="formBasicEmail" >
                      <Form.Control
                        as="textarea"
                        placeholder="Write your cover letter"
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicName" className="mr-2">
                      <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPhoneNo">
                      <Form.Control
                        type="number"
                        placeholder="Enter your phone number"
                      />
                    </Form.Group>

                    <Button
                      style={{
                        backgroundColor: "#007ddf",
                        borderRadius: "20px",
                        padding: "5px 15px",
                      }}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Form>
                </div>
              </Col>

              <Col xs={6} sm={6} lg={3}>
                <div className="pt-5">
                  {/* <h5>Head Office</h5> */}
                  <FontAwesomeIcon
                    icon="map-marker-alt"
                    size="2x"
                    color="#007DDF"
                  />
                  <ul className="list-unstyled pt-3">
                    {/* <li>P.O.Box: 32893 – Abu Dhabi U.A.E.</li>
                  <li>Phone: +971- 2- 6760555</li>
                  <li>Fax : +971- 2- 6760888</li>
                  <li>Store : +971- 2- 5543425</li>
                  <li>info@binalimed.com</li>
                  <li>www.binalimed.com</li> */}

                    <li>
                      <FontAwesomeIcon
                        icon="caret-right"
                        size="sm"
                        className="mr-2"
                        color="#007DDF"
                      />
                      OFFICE - 601, MAZYAD MALL TOWER1, MUHAMMED BIN ZAYED CITY,
                      MUSAFFAH, ABU DHABI
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="caret-right"
                        size="sm"
                        className="mr-2"
                        color="#007DDF"
                      />
                      SUITE NO 806, SHOBA IVORY 1 BUSINESS BAY, Dubai
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="caret-right"
                        size="sm"
                        className="mr-2"
                        color="#007DDF"
                      />
                      AL-HARAIR STREET NO-57 SHARIKAT, AL-AIN
                    </li>
                  </ul>
                  <FontAwesomeIcon icon="envelope" size="2x" color="#007DDF" />
                  <ul className="list-unstyled pt-3">
                    <li>
                      <FontAwesomeIcon
                        icon="caret-right"
                        size="sm"
                        className="mr-2"
                        color="#007DDF"
                      />
                      info@binalimed.com
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs={6} sm={6} lg={3}>
                <div className="p-5">
                  <FontAwesomeIcon
                    icon="phone"
                    size="2x"
                    color="#007DDF"
                  />
                  <ul className="list-unstyled pt-3">
                    <li>
                      <FontAwesomeIcon
                        icon="caret-right"
                        size="sm"
                        className="mr-2"
                        color="#007DDF"
                      />
                      Abu Dhabi <br/>+971- 2- 6760555
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="caret-right"
                        size="sm"
                        className="mr-2"
                        color="#007DDF"
                      />
                     Dubai <br/> +971 -4- 452002
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="caret-right"
                        size="sm"
                        className="mr-2"
                        color="#007DDF"
                      />
                     Alain <br/> +971- 3- 7824596
                    </li>
                  </ul>
                  <FontAwesomeIcon icon="fax" size="2x" color="#007DDF" />
                  <ul className="list-unstyled pt-3">
                    <li>
                      <FontAwesomeIcon
                        icon="caret-right"
                        size="sm"
                        className="mr-2"
                        color="#007DDF"
                      />
                      +971- 2- 6760888
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="caret-right"
                        size="sm"
                        className="mr-2"
                        color="#007DDF"
                      />
                      +971-4-4423503
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="caret-right"
                        size="sm"
                        className="mr-2"
                        color="#007DDF"
                      />
                      +971- 3- 7824597
                    </li>
                    {/* <li>Dubai:</li>
                    <li>Phone : +971-4-452002</li>
                    <li>Fax : +971-4-4423503</li>
                    <li>Al Ain :</li>
                    <li>Phone: +971- 3- 7824596</li>
                    <li>Fax : +971- 3- 7824597</li> */}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <div
                style={{ width: "100%", height: "400px", marginBottom: "5px" }}
              >
                <SimpleMap />
              </div>
            </Row>
          </Container>
        </div>
      </motion.div>
    </>
  );
}

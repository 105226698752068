import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import "./styles/Customer.css";
export default function Customers() {
  const customersVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.5,
      },
    },
  };

  const customerList = [
    {
      id: "1",
      title: "Ministry of Health",
      image: "/assets/customersImages/ministryOfHealth.jpg",
    },
    {
      id: "2",
      title: "Corniche Hospital",
      image: "/assets/customersImages/cornicheHospital.jpg",
    },
    {
      id: "3",
      title: "Tawam Hospital, Al Ain",
      image: "/assets/customersImages/tawamHospital2.jpg",
    },
    {
      id: "4",
      title: "General Health Authority",
      image: "/assets/customersImages/healthAuthority.jpg",
    },
    {
      id: "5",
      title: "Shaikh Khalifa Medical City",
      image: "/assets/customersImages/sheikhKhalifaMedi.jpg",
    },
    {
      id: "6",
      title: "Al Noor Hospital",
      image: "/assets/customersImages/alnoorHosptial.jpg",
    },
    {
      id: "7",
      title: "Department of Health Dubai",
      image: "/assets/customersImages/departmentOfHealthDubai.jpg",
    },
    {
      id: "8",
      title: "Al Ain Hospital",
      image: "/assets/customersImages/alainHospital.jpg",
    },
    {
      id: "9",
      title: "NMC Hospital",
      image: "/assets/customersImages/nmc.jpg",
    },
    {
      id: "10",
      title: "Centre of Ambulance , Dubai",
      image: "/assets/customersImages/centerOfAmbulance.jpg",
    },
    {
      id: "11",
      title: "Abu Dhabi Municipality",
      image: "/assets/customersImages/abudhabiCityMunicipality.jpg",
    },
    {
      id: "12",
      title: "Al Mafraq Hospital",
      image: "/assets/customersImages/almafraq.jpg",
    },
    {
      id: "13",
      title: "Ambulatory Healthcare Services, Abu Dhabi",
      image: "/assets/customersImages/ambulatoryHealth.jpg",
    },
    {
      id: "14",
      title: "American Hospital ADNOC (Supplier Code :903831)",
      image: "/assets/customersImages/americanHospital.jpg",
    },
    {
      id: "15",
      title: "Abu Dhabi Distribution Co",
      image: "/assets/customersImages/abudhabiDistributionCo.jpg",
    },
    {
      id: "16",
      title: "Al Rahba Hospital",
      image: "/assets/customersImages/alrahhbaHospital.jpg",
    },
    {
      id: "17",
      title: "G.H.Q. Armed Forces. GHQ Police, Ambulance & Rescue, Abu Dhabi",
      image: "/assets/customersImages/ghqArmedForces.jpg",
    },
  ];

  const customerCard = customerList.map((customer) => {
    return (
      <motion.div key={customer.id} whileHover={{ scale: 1.1 }}>
        
        <Card
          style={{
            width: "15rem",
            margin: "20px",
            borderRadius: "20px",
          }}
          className="shadow"
        >
          <Card.Img
            variant="top"
            src={customer.image}
            alt="Customer images"
            style={{ padding: "10px" }}
          />
          <Card.Body className="customer-Cardbody">
            <Card.Title className="card-title font-weight-bold">
              {customer.title}
            </Card.Title>
          </Card.Body>
        </Card>
      
      </motion.div>
    );
  });

  return (
    <>
      <motion.div
        variants={customersVariants}
        initial="hidden"
        animate="visible"
        style={{ backgroundColor: "#E3F2FF" }}
      >
        <Container>
          <Row>
            <Col>
              <div className="pt-3">
                <h3>OUR CUSTOMERS AND MARKETING</h3>
                <p>
                  Our product line is backed by a dedicated team of sales
                  professionals who are ever ready to attend to the maximum
                  needs of our customers. Deliveries are carefully scheduled to
                  fulfill customer needs satisfactorily. We have a well
                  maintained fleet of vehicles and experienced drivers to
                  support the logistic functions.
                </p>
                <p>
                  Our marketing team is equipped to provide comprehensive
                  information to customers to help them select the right
                  products. Our on-line store is equipped to conveniently select
                  and buy products.
                </p>
              </div>
            </Col>
          </Row>
          <br />
          <div className="card-deck">{customerCard}</div>
        </Container>
      </motion.div>
    </>
  );
}

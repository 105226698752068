import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="main-footer">
      <Container>
        <Row>
          <Col xs={6} lg={3}>
            <h4 className="footer-h4">Bin Ali</h4>
            <p>
              Bin Ali Medical Supplies LLC is a reputed Medical Supplies Dubai
              company built on a distinct vision. Since early 2000, BAMS has
              grown to be a leading supplier of a wide range of excellent
              products.
            </p>
          </Col>
          <Col xs={6} lg={3}>
            <h4 className="footer-h4">MOH</h4>
            <ul className="list-unstyled">
              <li>
                APPROVAL NUMBER :
              </li>
              <li>
                S2QH0AQ2-261022
              </li>                 
              <li>
                DATE : 19/10/2022 TO 19/10/2023
              </li>            
            </ul>
          </Col>
          <Col xs={6} lg={3}>
            <h4 className="footer-h4">BRANCH OFFICES</h4>
            <ul className="list-unstyled">
              <li>Dubai - +971-4–4520022 </li>
              <li>Al Ain - +971-3-7824596</li>
              <li>AbuDhabi - +971-3-7824596</li>
            </ul>
          </Col>
          <Col xs={6} lg={3}>
            <h4 className="footer-h4">KEEP IN TOUCH</h4>
            <img src="/assets/toucWithUs/facebook.png" alt="binali facebook" />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <img src="/assets/toucWithUs/linkedin.png" alt="binali linkedin" />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <img src="/assets/toucWithUs/whatsapp.png" alt="binali whatsapp" />
          </Col>
        </Row>
      </Container>
      <div className="footer-bottom">
      <Container>
        <Row>
          <Col>
            
              <p className="text-xs-center">
                &copy; {new Date().getFullYear()} Binali Medical Supplies | All
                Rights Reserved | Terms of Services | Privacy
              </p>
            
          </Col>
        </Row>
      </Container>
      </div>
    </div>
  );
}

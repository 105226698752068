import React from "react";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import "./styles/OurProducts.css";
import { motion } from "framer-motion";
export default function OurProducts() {
  const proudctVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.5,
      },
    },
  };
  const ourProdcts = [
    {
      id: 1,
      title: "LABORATORY CONSUMABLES",
      image: "/assets/ourProducts/laboratoryConsumables.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/1-Histologypathologycytology.pdf",
    },
    {
      id: 2,
      title: "MEDICAL EQUIPMENTS",
      image: "/assets/ourProducts/medicalEquipment.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/16-MEDICAL-EQUPMENTS.pdf",
    },
    {
      id: 3,
      title: "WOUND CARE PRODUCTS",
      image: "/assets/ourProducts/woundCare.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/10-WOUND-CARE-PRODUCTS.pdf",
    },
    {
      id: 4,
      title: "LINEN,UNIFORMS AND SHOES",
      image: "/assets/ourProducts/uniform.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/14-LINEN-UNIFORMS-ANDSHOES.pdf",
    },
    {
      id: 5,
      title: "EMERGENCY AND FIRST AID",
      image: "/assets/ourProducts/firstAid.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/08-EMERGENCY-AND-FIRST-AID-.pdf",
    },
    {
      id: 6,
      title: "ORTHOPEDIC PRODUCTS",
      image: "/assets/ourProducts/arturo.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/7-ORTHOPEDIC-PRODUCTS-II-.pdf",
    },
    {
      id: 7,
      title: "RADIOLOGY PRODUCTS",
      image: "/assets/ourProducts/radio.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/11-RADIOLOGY-PRODUCTS.pdf",
    },
    {
      id: 8,
      title: "MEDICAL FURNITURES",
      image: "/assets/ourProducts/medicalFurniture.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/15-MEDICAL-FURNITURES.pdf",
    },
    {
      id: 9,
      title: "ANESTHESIA AND RESPIRATORY",
      image: "/assets/ourProducts/anesthesia1.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/3-ANESTHESIA-AND-RESPIRATORY.pdf",
    },
    {
      id: 10,
      title: "DENTAL CONSUMABLES",
      image: "/assets/ourProducts/dentalC.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/12-DENTAL-CONSUMABLES.pdf",
    },
    {
      id: 11,
      title: "SURGICAL PRODUCTS",
      image: "/assets/ourProducts/surgical1.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/08-EMERGENCY-AND-FIRST-AID-.pdf",
    },
    {
      id: 12,
      title: "NURSING CONSUMABLES",
      image: "/assets/ourProducts/nurss.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/02-NURSING-CONSUMABLES.pdf",
    },
    {
      id: 13,
      title: "IN CONTINENCE AND UROLOGY PRODUCTS",
      image: "/assets/ourProducts/urology.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/04-IN-CONTINENCE-AND-UROLOGY-PRODUCTS.pdf",
    },
    {
      id: 14,
      title: "SURGICAL & DENTAL INTRUMENTS",
      image: "/assets/ourProducts/surgDe.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/17-SURGICAL-AND-DENTAL-INSTRUMENTS-II.pdf",
    },
    {
      id: 15,
      title: "STERILIZATION PRODUCTS",
      image: "/assets/ourProducts/sterialization.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/05-STERILIZATION-PRODUCTS.pdf",
    },
    {
      id: 16,
      title: "INFECTION CONTROL PRODUCTS",
      image: "/assets/ourProducts/covic1.jpg",
      downloadLink:
        "http://binalimed.com/wp-content/uploads/2015/06/06-INFECTION-CONTROL-PRODUCTS-.pdf",
    },
  ];

  const productList = ourProdcts.map((ourProduct) => {
    return (
      <motion.div key={ourProduct.id} whileHover={{ scale: 1.1 }}>
        <Card
          style={{
            width: "15rem",
            margin: "20px",
            borderRadius: "20px",
          }}
          className="shadow"
        >
          <Card.Img
            variant="top"
            src={ourProduct.image}
            alt="Medical equipment images"
            style={{
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }}
          />
          <Card.Body className="products-Cardbody">
            <Card.Title className="card-title font-weight-bold">
              {ourProduct.title}
            </Card.Title>

            <Button style={{backgroundColor : "#007ddf" , borderRadius : "20px"}}>
              <a
                href={ourProduct.downloadLink}
                target="_blank"
                className="card-button p-2"
                rel="noopener noreferrer"
              >
                Download Brochure
              </a>
            </Button>
          </Card.Body>
        </Card>
      </motion.div>
    );
  });

  return (
    <>
      <motion.div
        variants={proudctVariants}
        initial="hidden"
        animate="visible"
        style={{ backgroundColor: "#E3F2FF" }}
      >
        <Container>
          <Row>
            <Col>
              <div className="pt-3">
                <h3>Our Products</h3>
                <p>
                  BAMS offers a wide range of high quality products in
                  healthcare. We would like to name some of our major companies
                  which we distribute in the U.A.E market.
                </p>
              </div>
            </Col>
          </Row>
          <Row>{productList}</Row>
        </Container>
      </motion.div>
    </>
  );
}

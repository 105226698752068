import React from "react";
import "./App.css";
import NavBar from "./components/layouts/Navbar";
import Footer from "./components/layouts/Footer";
import { Route, Switch } from "react-router-dom";
import About from "./components/pages/About";
import Home from "./components/pages/Home";
import Careers from "./components/pages/Careers";
import Contact from "./components/pages/Contact";
import OurProducts from "./components/pages/OurProducts";
import Customers from "./components/pages/Customers";
import NotFound from "./components/pages/NotFound";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowCircleRight,
  faMapMarkerAlt,
  faEnvelope,
  faFax,
  faCaretRight,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
//import ReactGa from "react-ga";
library.add(
  fab,
  faArrowCircleRight,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faFax,
  faCaretRight
);

function App() {
  //   let location = useLocation();
  //   useEffect(() => {
  //   ReactGa.initialize("UA-169393813-2");

  //   ReactGa.pageview(location.pathname);
  // }, [location]);
  return (
    <div className="App">
      <NavBar />

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/careers" component={Careers} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/products" component={OurProducts} />
        <Route exact path="/customers" component={Customers} />
        <Route component={NotFound} />
      </Switch>

      <Footer />
    </div>
  );
}

export default App;

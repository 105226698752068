import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 24.37345171390133,
      lng: 54.53800769733018,
    },
    zoom: 12.5,
  };
  renderMarkers(map, maps) {
     new maps.Marker({
      position: this.props.center,
      map,
      title: "Bin Ali Medical Supplies",
    });
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBMjs3g-x8FobKggsKLHn3cj7GZrSVCtEU" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
        >
          <AnyReactComponent
            lat={24.37345171390133}
            lng={54.53800769733018}
            text="Bin Ali Medical Supplies"
            style={{ color: "red" }}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;

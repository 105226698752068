import React from "react";
import "./styles/Home.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeCarousel from "./HomeCarousel";

export default function Home() {
  const homeVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.5,
        delayChildren: 1,
      },
    },
  };

  const firstVariant = {
    hidden: {
      opacity: 0,
      y: 5,
    },

    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const homeDivSecond = {
    hidden: {
      opacity: 0,
      y: 30,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeOut",
        duration: 0.7,
        delay: 0.5,
      },
    },
  };
  return (
    <>
      <motion.div initial="hidden" animate="visible">
        <motion.div className="first-div" variants={homeVariants}>
          <motion.div variants={homeDivSecond}>
            <Container>
              <div className="first-div-child">
                <h1>
                  One of the Leading Medical Suppliers in <b>UAE</b>
                </h1>
                <p>
                  Being the leading supplier of healthcare products in the
                  region, Bin Ali Medical Supplies LLC deals with an extensive
                  range of high-value products for the benefit of our loyal
                  customer base.
                </p>
                <Button>
                  <a
                    href="#home-div"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Read More
                  </a>
                </Button>
              </div>
            </Container>
          </motion.div>
        </motion.div>
        <div className="home-div" id="home-div">
          <Container>
            <motion.div variants={firstVariant} className="welcome-heading">
              <h1 style={{ color: "#111638" }}>Welcome to</h1>
              <h1 style={{ color: "#007DDF" }}>Bin Ali Medical Supplies LLC</h1>
            </motion.div>
            <hr />
            <motion.div className="home-div-second" variants={homeDivSecond}>
              <Row>
                <Col lg={6}>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Being the leading supplier of
                    healthcare products in the region, Bin Ali Medical Supplies
                    LLC deals with an extensive range of high-value products for
                    the benefit of our loyal customer base. Surgical Products,
                    Nursing Consumables, Anesthesia and Respiratory Products,
                    Incontinence & Urology Products, Sterilization Products CSSD
                    Products, Infection Control Products, Orthopedic Products,
                    Emergency & First Aid Products, Laboratory Consumables,
                    Wound Care Products, Radiology Products, Dental Consumables,
                    IV Solutions, Linen, Uniforms & Shoes, Medical Furniture,
                    Medical Equipments, Surgical & Dental Instruments – so goes
                    the list.
                  </p>
                </Col>
                <Col lg={3}>
                  <ul className="list-unstyled">
                    <li>
                      <FontAwesomeIcon
                        icon="arrow-circle-right"
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                      Market Share
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="arrow-circle-right"
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                      Financial Position
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="arrow-circle-right"
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                      Customer loyalty
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="arrow-circle-right"
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                      Production/Distribution capacity
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="arrow-circle-right"
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                      Global/regional expansion
                    </li>
                  </ul>
                </Col>
                <Col lg={3}>
                  <ul className="list-unstyled">
                    <li>
                      <FontAwesomeIcon
                        icon="arrow-circle-right"
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                      Inventory System
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="arrow-circle-right"
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                      Product Quality
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="arrow-circle-right"
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                      Organizational structure
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="arrow-circle-right"
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                      Customer Service
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon="arrow-circle-right"
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                      Management Expertise
                    </li>
                    {/* <li>
                      <i
                        className="fa fa-arrow-circle-right"
                        style={{ paddingRight: "10px" }}
                      ></i>
                      Cost competitiveness
                    </li> */}
                  </ul>
                </Col>
              </Row>
            </motion.div>
          </Container>
        </div>

        <div className="products">
          <Container>
            <h3 className="text-center p-3">Our Products</h3>
            <hr />
            <HomeCarousel />
          </Container>
        </div>
        <div className="ceo">
          <Container>
            <h3 className="pt-3">CEO'S MESSAGE</h3>

            <br />
            <h6>Dear Visitor ,</h6>
            <br />
            <p style={{ width: "70%" }}>
              Bin Ali Medical Supplies LLC is a reputed Medical Supplies Comapny
              based in United Arab Emirates built on a distinct vision . It is
              driven by a set of great values, which is amply reflected in every
              aspect of our business. Since its inception in the early 2000,
              BAMS has grown to be a leading supplier of a wide range of
              excellent products. Its powerful presence in the market is
              supported by a loyal customer base. Our commitment to care and
              excellence is reflected in our core values and beliefs. They give
              us the impetus to strive for excellence. They are the driving
              force behind everything we do. We continuously endeavor to provide
              better products and service. We envisage BAMS as an enduring
              world-class company engaged in manufacturing and distribution of
              superb quality Medical Supplies Dubai and healthcare products that
              will be affordable to everyone who seeks a quality-value balance.
              We will continually endeavor to be a socially responsible company.
              <br />
              <p></p>
              Thank you for your interest in Bin Ali Medical Supplies Dubai. We
              look forward to the opportunity to serve you.
            </p>
            <br />
            <h3>OMAR ALI</h3>
            <p></p>
            <h6>Bin Ali Medical Supplies LLC</h6>
          </Container>
        </div>
        {/* <div className=""></div> */}
      </motion.div>
    </>
  );
}

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style,  background: "red" }}
//       onClick={onClick}
//     />
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "green" }}
//       onClick={onClick}
//     />
//   );
// }

export default function SimpleSlider() {
  const productsImages = [
    {
      id: 1,
      image: "./assets/binaliProducts/anika.jpg",
    },
    {
      id: 2,
      image: "./assets/binaliProducts/assutSutures.jpg",
    },
    {
      id: 3,
      image: "./assets/binaliProducts/barray.jpg",
    },
    {
      id: 4,
      image: "./assets/binaliProducts/biomet.jpg",
    },
    {
      id: 5,
      image: "./assets/binaliProducts/bloodcare.jpg",
    },
    {
      id: 6,
      image: "./assets/binaliProducts/citotest.jpg",
    },
    {
      id: 7,
      image: "./assets/binaliProducts/cvMedia-r.png",
    },
    {
      id: 8,
      image: "./assets/binaliProducts/darco.jpg",
    },
    {
      id: 9,
      image: "./assets/binaliProducts/eihf.jpg",
    },
    {
      id: 10,
      image: "./assets/binaliProducts/gd.jpg",
    },
    {
      id: 11,
      image: "./assets/binaliProducts/gobiz.jpg",
    },
    {
      id: 12,
      image: "./assets/binaliProducts/herniamesh.jpg",
    },
    {
      id: 13,
      image: "./assets/binaliProducts/hyalo.jpg",
    },
    {
      id: 14,
      image: "./assets/binaliProducts/interster.jpg",
    },
    {
      id: 15,
      image: "./assets/binaliProducts/lifeline.jpg",
    },
    {
      id: 16,
      image: "./assets/binaliProducts/lifeSciencePlus.png",
    },
    {
      id: 17,
      image: "./assets/binaliProducts/metrex.jpg",
    },
    {
      id: 18,
      image: "./assets/binaliProducts/microbar.jpg",
    },
    {
      id: 19,
      image: "./assets/binaliProducts/mirion.jpg",
    },
    {
      id: 20,
      image: "./assets/binaliProducts/pirrone.jpg",
    },
    {
      id: 21,
      image: "./assets/binaliProducts/bloodstop2.jpg",
    },
    {
      id: 22,
      image: "./assets/binaliProducts/yoorimCorp.jpg",
    },
  ];

  const images = productsImages.map((image) => {
    return (
      <div key={image.id}>
        <img src={image.image} alt="Our Products" />
      </div>
    );
  });

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 8,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    pauseOnHover: false,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 8,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>{images}</Slider>
    </div>
  );
}

import React, { useState } from "react";
import { Navbar, Nav, Container, NavItem, Button } from "react-bootstrap";
//import logo from "./logo1.png";
import { Link, NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import "./Navbar.css";

function NavBar() {
  const [expanded, setExpanded] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [textColor, setTextColor] = useState("white");

  return (
    <motion.div
      initial={{ opacity: 0, y: -250 }}
      animate={{ y: 0 , opacity : 1}}
      transition={{duration: 0.3, type: "spring" }}
    >
      <Navbar
        expanded={expanded}
        expand="lg"
        className="main-navbar shadow-sm" //fixed-top=> for sticky..
        bg={backgroundColor}
        style={{ borderBottom: "1px solid #007ddf" }}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src="/assets/logo-svg.svg"
              width="130"
              height="62"
              className="d-inline-block align-top"
              alt="Bin Ali Medical Supplies"
              onClick={() => {
                setBackgroundColor("transparent");
                setTextColor("white");
              }}
              style={{ color: textColor }}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : "expanded")}
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <NavItem>
                <Nav.Link
                  onClick={(e) => {
                    setExpanded(false);
                    setBackgroundColor("transparent");
                    setTextColor("white");
                  }}
                  as={NavLink}
                  to="/"
                  style={{ color: textColor, padding: "16px 20px" }}
                >
                  Home
                </Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link
                  as={NavLink}
                  to="/about"
                  onClick={(e) => {
                    setExpanded(false);
                    setBackgroundColor("#E8E9F1");
                    setTextColor("#1F2B76");
                  }}
                  style={{ color: textColor, padding: "16px 20px" }}
                >
                  About
                </Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link
                  onClick={(e) => {
                    setExpanded(false);
                    setBackgroundColor("#E8E9F1");
                    setTextColor("#1F2B76");
                  }}
                  as={NavLink}
                  to="/products"
                  style={{ color: textColor, padding: "16px 20px" }}
                >
                  Our Products
                </Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link
                  onClick={(e) => {
                    setExpanded(false);
                    setBackgroundColor("#E8E9F1");
                    setTextColor("#1F2B76");
                  }}
                  as={NavLink}
                  to="/customers"
                  style={{ color: textColor, padding: "16px 20px" }}
                >
                  Customers
                </Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link
                  onClick={(e) => {
                    setExpanded(false);
                    setBackgroundColor("#E8E9F1");
                    setTextColor("#1F2B76");
                  }}
                  as={NavLink}
                  to="/careers"
                  style={{ color: textColor, padding: "16px 20px" }}
                >
                  Careers
                </Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link
                  onClick={(e) => {
                    setExpanded(false);
                    setBackgroundColor("#E8E9F1");
                    setTextColor("#1F2B76");
                  }}
                  as={NavLink}
                  to="/contact"
                  style={{ color: textColor, padding: "16px 20px" }}
                >
                  Contact Us
                </Nav.Link>
              </NavItem>
              <Nav.Link
                href="http://binalimain.qnoddns.org.cn:9090/BAMS/CustomerLogin"
                target="_blank"
                style={{
                  padding: "10px 20px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#007ddf",
                    borderRadius: "20px",
                  }}
                >
                  Web Store
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </motion.div>
  );
}

export default NavBar;

import React from "react";
import { Container } from "react-bootstrap";
export default function NotFound() {
  return (
    <Container>
      <div className='text-center'>
        <h1>404</h1>
        <br/>
        <h2>Soory :( We cant found what you are looking for...</h2>
      </div>
    </Container>
  );
}

import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { motion } from "framer-motion";

export default function Careers() {
  const careersVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <motion.div
        variants={careersVariants}
        initial="hidden"
        animate="visible"
        style={{ backgroundColor: "#E3F2FF" }}
      >
        <Container>
          <div className="pt-3">
            <h3>Careers</h3>
            <Row>
              <Col xs={12} sm={12} lg={6}>
                <div style={{ marginTop: "10px" }}>
                  <img
                    src="/assets/3.jpg"
                    alt="Bin ali Careers"
                    style={{
                      float: "left",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                    width="550"
                    className="img-fluid"
                  />

                  <p>
                    <b>Build your Career with Us.</b> Our career not only brings
                    a smile to your face but also commands respect and handsome
                    earnings. Learn more about the exciting career options we
                    have. Build your Career with Us. Our career not only brings
                    a smile to your face but also commands respect and handsome
                    earnings. Learn more about the exciting career options we
                    have.
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={12} lg={6}>
                <div>
                  <h5>Apply for Job</h5>
                  <hr />
                  <Form
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      paddingRight: "30px",
                    }}
                  >
                    <Form.Group controlId="formBasicName">
                      <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPhoneNo">
                      <Form.Control
                        type="number"
                        placeholder="Enter your phone number"
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        as="textarea"
                        placeholder="Write your cover letter"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.File
                        id="exampleFormControlFile1"
                        label="Add Resume (Use doc | docx | pdf | xls | xlsx filetypes only)"
                      />
                    </Form.Group>
                    <Button
                      style={{
                        backgroundColor: "#007ddf",
                        borderRadius: "20px",
                        padding: "5px 15px",
                      }}
                      type="submit"
                    >
                      Apply Now
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </motion.div>
    </>
  );
}

import React from "react";
import { motion } from "framer-motion";
import "./styles/About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

export default function About() {
  const aboutVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <>
      <motion.div variants={aboutVariants} initial="hidden" animate="visible">
        <div className="About-cards">
          <div className="container">
            <div className="row">
              <div className="col col-lg-7 col-sm-12  mt-5 mb-5 p-4 About-cards-1">
                <h4 style={{ color: "#007DDF" }}>WHO WE ARE</h4>
                <p>
                  Bin Ali Medical Supplies LLC was established in January 2002
                  with its headquarters in Abu Dhabi and two branches in Dubai
                  and Al-Ain. The Company soon grew from a promising business
                  enterprise to the favored supplier of affordable and excellent
                  quality medical products to the regionals medical and
                  scientific community. Thanks to our unique business philosophy
                  and amazing customers’ support, today, Bin Ali stands tall as
                  the leading supplier chain of international-quality healthcare
                  products in the UAE. Bin Ali Medical Supplies is built on
                  certain values and core principles that make us much more than
                  a business concern. A socially committed and responsible team
                  of professionals, we fully realise the importance of having to
                  deliver the very best of products so that our customers would
                  stand to benefit from it. The reason why we are the preferred
                  partner of many major Government and private hospitals,
                  clinics, and pharmacies across Middle East, is no secret. It
                  is the result of our honest approach and transparent deals,
                  with no compromise whatsoever in quality.
                </p>
                <h4 style={{ color: "#007DDF" }}>OUR VISION</h4>
                <p>
                  Aspire to become the global industry leader by providing
                  innovative, practical and affordable healthcare solutions that
                  would significantly improve people's life across the world.
                </p>
              </div>
              <div className="col-lg-4 col-sm-12 offset-lg-1 offset-md-0 mt-5 mb-5 p-4 About-cards-2 ">
                <h4 style={{ color: "#007DDF" }}>OUR MISSION</h4>

                <p>
                  Persistently try hard to make BAMS a globally recognised
                  healthcare company by focusing stringently on the core values
                  on which our foundation is built. Not just fulfill, but excel
                  customer expectations by endowing unparalleled customer
                  support. Deploy state-of-the-art and superior technologies,
                  always. Uphold the ethical values that make our business
                  sector special. Safeguard the rights and privileges of our
                  investors and employees. Remain responsive to societal and
                  environmental concerns by concentrating on sustainable growth.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutSecond-Row">
          <div className="container">
            <h4 className="about-principles">OUR VALUES & PRINCIPLES</h4>
            <div className="row principles-child-1">
              <div className="col-sm-6 col-lg-3 principles-child text-center">
                <h6 className="principles-heading">Customers</h6>
                <p>
                  Our customers come first. They deserve the best of what we can
                  offer.
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 principles-child text-center">
                <h6 className="principles-heading">Market</h6>
                <p>
                  We are dedicated to achieving enduring success as a global
                  player
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 principles-child text-center">
                <h6 className="principles-heading">Philosophy</h6>
                <p>
                  Our leadership is dedicated to the philosophy that holds
                  values and principles above profit.
                </p>
              </div>

              <div className="col-sm-6 col-lg-3 principles-child text-center">
                <h6 className="principles-heading">Technology</h6>
                <p>
                  We will continuously strive to harness the best available
                  technologies to achieve our goals
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 principles-child text-center">
                <h6 className="principles-heading">Products & Services</h6>
                <p>
                  We operate to find/ produce the best medical products and to
                  provide dedicated service to society to ensure their health
                  and life protection
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 principles-child text-center">
                <h6 className="principles-heading">
                  Corporate Social Responsibility
                </h6>
                <p>
                  We are committed to contributing to the society and the
                  environment by being a considerate and responsible corporate
                  citizen
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 principles-child text-center">
                <h6 className="principles-heading">Standards Of Conduct</h6>
                <p>
                  We expect our employees to show the highest degree of ethics
                  in all aspects of our business. We greatly value and respect
                  personnel and professional integrity.
                </p>
              </div>
              <div className="col-sm-6 col-lg-3 principles-child text-center">
                <h6 className="principles-heading">Growth & Profitability</h6>
                <p>
                  We will conduct our business ethically and prudently to
                  fulfill our stakeholder’s expectations
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="about-thirdRow">
          <div className="container">
            <div>
              <h4 className="text-center">OUR MARKETING & SALES TEAM</h4>
            </div>
            <div className="row pt-4 pb-4">
              <div className="col-lg-6 about-thirdRow-text">
                <p>
                  The team is lead by Aji Mohammed Master in commerce with the
                  support of 22 sales staff and 5 Marketing staff all of them
                  are experienced and trained in their fields, with the product
                  specialist Dr. Jaya Thomas masters in pharmacy degree having
                  practical experience of 15 years. And with enough logistic
                  staff and support.
                </p>
                <ul className="list-unstyled">
                  <li>
                  <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                    Installation of Equipment & Filing of documents.
                  </li>
                  <li>
                  <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                    Equipment inventory Register (both soft copy & hard copy)
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />Electrical
                    Safety Analyzer Testing
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                    Vital Sign Simulation & Calibration Breakdown Service.
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                    Daily Checking of critical Equipment & its document filing.
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />Maintenance
                    Contracts follow up. (AMC & CMC)
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />PM
                    Scheduling.
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />PM to be
                    performed as per the standard procedure
                  </li>
                </ul>
              </div>
              <div className="col col-lg-6 ">
                <img
                  src="/assets/sales.jpg"
                  className="img-fluid rounded"
                  alt="Bin Ali sales team"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="biomedical-team">
          <div className="container">
            <div>
              <h4 className="text-center pt-5">OUR BIOMEDICAL TEAM</h4>
            </div>
            <div className="row pt-3">
              <p className="w-30">
                The team is lead by Vineeth Nair Senior Biomedical Engineer with
                the support of 2 Biomedical Technicians all of them are
                experienced and trained in handling Critical Care Equipment,
                Operation Theatre Equipment & Lab Equipment. The main activities
                of the BME department includes
              </p>
              <div className="col-xs-12 col-sm-12 col-lg-6 p-3">
                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                    Installation of Equipment & Filing of documents.
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                    Equipment inventory Register (both soft copy & hard copy)
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />Electrical
                    Safety Analyzer Testing
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                    Vital Sign Simulation & Calibration Breakdown Service.
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                    Daily Checking of critical Equipment & its document filing.
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />Maintenance
                    Contracts follow up. (AMC & CMC)
                  </li>
                </ul>
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-6 p-3">
                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />PM
                    Scheduling.
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />PM to be
                    performed as per the standard procedure
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />Training of
                    Paramedic Staffs.
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />
                    Condemnation of Equipment.
                  </li>
                  <li>
                    <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        color="#007ddf"
                        style={{ marginRight: "10px" }}
                      />Stocking of
                    Spares & Accessories.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
